/*//*/ /*/ Colors /*/ /*//*/
/*/

<- from designer ->
Midnight Forest Blue: #002A3A

Calm Grey: #717C7D
French Grey: #A7BDB1

Fern Green: #A7BDB1
Dark Green: 36E7C7C
---------
Yellow: #FDBB30
Dark: #BE8C24
Light: #FDC959
Xlight: #FEE4AC
xxlight: #FFF1D6

Red: #E3352B
Dark: #AA2820
Light: #EE8680
Xlight: #F9D7D5

Grey: #A1A1A1
Dark: #6B6B6B
Light: #D6D6D6

Fonts: 
CormorantGaramond (Headings)
Futura Book (Paragraph)
PinyonScript (Logo Script)

/*//*//*//*//*//*//*//*/*/

@font-face {
    font-family: "CormorantGaramond-Light";
    src: url(../fonts/Cormorant_Garamond/CormorantGaramond-Light.ttf);
}
@font-face {
    font-family: "CormorantGaramond-Regular";
    src: url(../fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf);
}
@font-face {
    font-family: "CormorantGaramond-Medium";
    src: url(../fonts/Cormorant_Garamond/CormorantGaramond-Medium.ttf);
}
@font-face {
    font-family: "CormorantGaramond-SemiBold";
    src: url(../fonts/Cormorant_Garamond/CormorantGaramond-SemiBold.ttf);
}
@font-face {
    font-family: "CormorantGaramond-Italic";
    src: url(../fonts/Cormorant_Garamond/CormorantGaramond-Italic.ttf);
}
@font-face {
    font-family: "CormorantGaramond-Bold";
    src: url(../fonts/Cormorant_Garamond/CormorantGaramond-Bold.ttf);
}

@font-face {
    font-family: "FuturaBook";
    src: url(../fonts/Futura_Book/FuturaBook.otf);
}

@font-face {
    font-family: "PinyonScript-Regular";
    src: url(../fonts/Pinyon_Script/PinyonScript-Regular.ttf);
}

.futura-book {
    font-family: "FuturaBook" !important;
}

.cormorant-garamond-regular {
    font-family: "CormorantGaramond-Regular" !important;
}

.cormorant-garamond-medium {
    font-family: "CormorantGaramond-Medium" !important;
}

.cormorant-garamond-bold {
    font-family: "CormorantGaramond-Bold" !important;
}

.pinyon-script {
    font-family: "PinyonScript-Regular" !important;
}

/* HTML Elements */
h1 {
    font-size: 2.8em;
    font-family: "CormorantGaramond-Medium";
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 1.5px;
}

h2 {
    font-size: 2.2em;
    font-family: "CormorantGaramond-Medium";
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 2px;
}

h3 {
    font-size: 1.8em;
    padding: 0px !important;
    margin: 0px !important;
    font-family: "CormorantGaramond-Medium";
    word-wrap: break-word !important;
    white-space: normal !important;
    text-transform: uppercase;
    font-weight: 100;
}

@media screen and (max-width: 900px) {
    h1 {
        font-size: 2.2em;
    }
    h2 {
        font-size: 1.8em;
    }
    h3 {
        font-size: 1.6em;
    }
}

h4 {
    font-family: "CormorantGaramond-Medium";
    text-transform: uppercase;
    font-weight: 100;
}

h5 {
    font-family: "CormorantGaramond-Medium";
    text-transform: uppercase;
    font-weight: 100;
}

p {
    font-family: "FuturaBook" !important;
    line-height: 2;
    font-size: 18px;
}

a {
    text-decoration: none;
    font-family: "FuturaBook";
    cursor: pointer;
}

ul,
ol,
li {
    font-family: "FuturaBook";
    line-height: 1.6;
    font-size: 18px;
}

div {
    font-family: "FuturaBook";
}

span {
    font-family: "FuturaBook";
}

/* Text size */
.xs-text {
    font-size: 8px !important;
}

.s-text {
    font-size: 12px !important;
}

.ms-text {
    font-size: 14px !important;
}

.m-text {
    font-size: 16px !important;
}

.l-text {
    font-size: 20px !important;
}

.xl-text {
    font-size: 24px !important;
}

/* Text color */
.black {
    color: black !important;
}
.white {
    color: white !important;
}

.dark-grey {
    color: #6B6B6B !important;
}
.grey {
    color: #A1A1A1 !important;
}
.light-grey {
    color: #D6D6D6 !important;
}

.dark-blue {
    color: #B9E5F3 !important;
}
.blue {
    color: #002A3A !important;
}
.light-blue {
    color: #72CCE6 !important;
}

.dark-yellow {
    color: #BE8C24 !important;
}
.yellow {
    color: #FDBB30 !important;
}
.light-yellow {
    color: #FEE4AC !important;
}

.dark-pink{
    color: #BB8F8D !important;
}
.pink {
    color: #F9BFBC !important;
}
.light-pink{
    color: #FBD9D7 !important;
}

.dark-red {
    color: #AA2820 !important;
}
.red {
    color: #FA3C57 !important;
}
.light-red {
    color: #EE8680 !important;
}

.dark-orange {
    color: #B35026 !important;
}
.orange {
    color: #FF8533 !important;
}
.light-orange {
    color: #FFB685 !important;
}

.dark-green {
    color: #269900 !important;
}
.green {
    color: #33CC00 !important;
}
.light-green {
    color: #8FC981 !important;
}
.xlight-green {
    color: #ADEB99 !important;
}

/* Text hover color */
.text-hover:hover {
    color: #002A3A  !important;
    cursor: pointer;
}

.text-hover-danger:hover {
    color: #E3352B  !important;
    cursor: pointer;
}

.text-hover-green:hover {
    color: #33CC00  !important;
    cursor: pointer;
}

.text-hover-dark-green:hover {
    color: #269900  !important;
    cursor: pointer;
}

.text-hover-yellow:hover {
    color: #FDBB30  !important;
    cursor: pointer;
}

.text-hover-light-yellow:hover {
    color: #FEE4AC  !important;
    cursor: pointer;
}

.text-hover-pink:hover {
    color: #FF81CC  !important;
    cursor: pointer;
}

/* Misc */
.center-text {
    text-align: center !important; 
}

.anchor {
    font-size: 0;
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
 }

 @media screen and (max-width: 900px) {
    .anchor {
        top: 0px !important;
     }
}


 .cursor-pointer {
    cursor: pointer;
}

.thick-font {
    font-weight: 900;
}

.semi-thick-font {
    font-weight: 500;
}





