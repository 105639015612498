.card {
    padding: 10%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 75%;
    border-radius: 5px;
    margin: 0 auto;
  }
  @media screen and (max-width: 560px) {
    .card {
        padding: 10% 10% 5% 10%;
        width: 75%;
      }
}
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  