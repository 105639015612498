label {
    color: black; 
    font-family: "CormorantGaramond-Bold";
    font-size: 22px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: -6px;
    opacity: 0.5;
    transition: 0.2s ease all; 
    -moz-transition: 0.2s ease all; 
    -webkit-transition: 0.2s ease all;
}

input {
    font-size: 18px;
    font-family: "FuturaBook";
    height: 25px;
    padding: 10px 10px 10px 5px;
    
    width: 90%;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    color: black;
    background: rgba(0,0,0,0);
    opacity: 0.8;
    transition: 0.2s ease;
}

input.white {
    border-bottom: 1px solid white;
    color: white;
    background: rgba(0,0,0,0);
}

input.white:focus {
    outline: none;
    opacity: 1;
  }

textarea {
    font-size: 16px;
    font-family: "FuturaBook";
    height: 25px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 90%;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    color: black;
    background: rgba(0,0,0,0);
    opacity: 0.8;
    transition: 0.2s ease;
    resize: none;
    overflow: hidden;
}

input:focus {
  outline: none;
  opacity: 1;
}

textarea:focus {
    height: 100px;
    resize: auto;
    outline: none;
    opacity: 1;
    overflow: auto;
}

textarea:valid{
    height: 100px;
}
  
/* active state */
input:focus ~ label, input:valid ~ label {
  top: -20px;
  font-size: 16px;
  color: #B1B1B1;
  opacity: 1;
}

textarea:focus ~ label, textarea:valid ~ label {
    top: -25px;
    font-size: 16px;
    color: #B1B1B1;
    opacity: 1;
}

button.send-btn {
    color: #B1B1B1;
    font-size: 26px;
    font-family: "CormorantGaramond-Regular";
    transition: 0.2s ease;
    padding: 0;
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    outline: inherit;
    border-bottom: 1px solid black;
}

button.send-btn:hover {
  background-color: #269900;
  color: white;
  padding: 5px 30px 5px 30px;
  border: 1px solid #269900;
  cursor: pointer;
}

button.subscribe-btn {
    color: #B1B1B1;
    font-size: 26px;
    font-family: "CormorantGaramond-Regular";
    transition: 0.2s ease;
    padding: 0;
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    outline: inherit;
    border-bottom: 1px solid black;
}

button.subscribe-btn:hover {
  background-color: #269900;
  color: white;
  padding: 5px 30px 5px 30px;
  border: 1px solid white;
  cursor: pointer;
}

.button-grey {
    color: #454545;
    font-family: "CormorantGaramond-Regular";
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #454545;
    position: relative;
    margin: 1em;
    display:inline-block;
    padding: 0.5em 1em;
    transition: all 0.3s ease-in-out;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
}

.button-grey:before, .button-grey:after {
    content: '';
    display: block;
    position: absolute;
    border-color: #454545;
    box-sizing: border-box;
    border-style: solid;
    width: 1em;
    height: 1em;
    transition: all 0.3s ease-in-out
}

.button-grey:before {
    top: -6px;
    left: -6px;
    border-width: 2px 0 0 2px;
    z-index: 2;
}

.button-grey:after {
    bottom: -6px;
    right: -6px;
    border-width: 0 2px 2px 0;
}

.button-grey:hover:before, .button-grey:hover:after {
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border-color: #717C7D
}

.button-grey:hover {
    color :#fff;
    background-color: #717C7D;
    border-color: #717C7D
}


  .button-plain-white {
    text-transform: uppercase;
    margin-top: -100px !important;
    padding: 10px 20px;
    font-family: "CormorantGaramond-Regular";
    font-weight: bold;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    color: #fff
  }
  /**** BTN No. 1 ****/
  .btn-1 {
    border: 1px solid #fff;
  }
  .btn-1:hover {
    background: #fff;
    color: black;
  }
  .btn-1:active {
    top: 1px;
  }


.panel {
    max-width: 960px;
    text-align: center;
    position: relative;
    margin: auto;
}
