
  /*======================================================
                            Navbar
    ======================================================*/
  .navbar {
    background: white;
    color: rgb(13, 26, 38);
    position: fixed;
    top: 0;
    height: 110px;
    line-height: 100px;
    width: 100%;
    z-index: 10;
  }
  
  .nav-wrapper {
    margin: auto;
    text-align: center;
    width: 90%;
  } 

  @media(max-width: 1300px) {
      .nav-wrapper {
        width: 100%;
      }
    }
  
  
  .logo {
    float: left;
    padding: 2px 0 0 5px;
    max-width: 400px;
  } 
  
  .navbar ul {
    float: right;
    list-style: none;
    /* margin-right: 14px; */
    margin-top: 60px;
    text-align: right;
    transition: transform 0.5s ease-out;
    -webkit-transition: transform 0.5s ease-out;
  } 
    @media(max-width: 1300px) {
      .navbar ul {
        display: none !important;
      }

      .logo {
        max-width: 300px;
      } 

      .navbar {
        height: 80px;
        line-height: 80px;
      }
      
    } 
    
    @media(orientation: landscape) {
        .navbar ul {
          display: inline-block;
        }
      }
  
  .navbar li {
    display: inline-block;
  }
  
  .navbar li span {    
    display: block;
    height: 50px;
    letter-spacing: 1px;
    margin: 0 20px;
    padding: 0 4px;
    position: relative;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
  
  .navbar li span:hover {
    /* border-bottom: 1px solid rgb(28, 121, 184); */
    color: rgb(167, 189, 177);
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
  }
  
  /* Animated Bottom Line */
  .navbar li span:before, .navbar li span:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    bottom: 25px;
    background: rgb(13, 26, 38);
  }
  
  .navbar li span:before {
    left: 0;
    transition: 0.5s;
  }
  
  .navbar li span:after {
    background: rgb(13, 26, 38);
    right: 0;
    /* transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1); */
  }
  
  .navbar li span:hover:before {
    background: rgb(13, 26, 38);
    width: 100%;
    transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
  }
  
  .navbar li span:hover:after {
    background: transparent;
    width: 100%;
    /* transition: 0s; */
  }

  /* Link text styling */
  .navbar li span a {
    color: rgb(0, 42, 58);
    text-decoration: none;
    font-size: 0.9em;
    font-family: "CormorantGaramond-Bold";
    text-transform: uppercase;
  }


  /* SELECTED Animated Bottom Line */
  .nav-selected:before, .nav-selected:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    bottom: 25px;
    left: 0;
    background: rgb(13, 26, 38);
  }

  .nav-selected:before {
    background: rgb(13, 26, 38);
    width: 100%;
    transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
  }
  
  .nav-selected:after {
    background: transparent;
    width: 90%;
    /* transition: 0s; */
  }
  

    
  
  
  /*======================================================
                      Mobile Menu Menu Icon
    ======================================================*/
  @media(max-width: 1300px) {
    .hamburger {
      cursor: pointer;
      display: block;
      position: fixed;
      right: 15px;
      top: 20px;
      height: 23px;
      width: 27px;
      z-index: 12;
    }
  }