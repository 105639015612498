.responsive {
    max-width: 100%;
    height: auto;
}

/* Imagine sizing */
img.xsmall {
    width: 100%;
    height: auto;
    max-width: 50px;
}

img.small {
    width: 100%;
    height: auto;
    max-width: 100px;
}

img.medium {
    width: 100%;
    height: auto;
    max-width: 150px;
}

img.large {
    width: 100%;
    height: auto;
    max-width: 300px;
}

img.xlarge {
    width: 100%;
    height: auto;
    max-width: 450px;
}

img.xxlarge {
    width: 100%;
    height: auto;
    max-width: 550px;
}

img.xxxlarge {
    width: 100%;
    height: auto;
    max-width: 700px;
}

/* em styling */
img.xs-em {
    height: 0.75em;
}
img.s-em {
    height: 1em;
}

img.m-em {
    height: 1.25em;
}

img.l-em {
    height: 1.75em;
}

img.xl-em {
    height: 2em;
}

/* Misc */
img.rounded {
    border-radius: 50%;
}

